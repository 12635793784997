<template>
  <v-tooltip bottom v-if="tooltip !== undefined && tooltip !=='' ">
    <template v-slot:activator="{ on, attrs }">
      <v-icon :color="color" :size="size" class="mr-1" v-bind="attrs" v-on="on">
        {{ portalIcon }}
      </v-icon>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
  <v-icon v-else :color="color" :size="size" class="mr-1">
    {{ portalIcon }}
  </v-icon>
</template>

<script>
import portalIcons from "../portal-icons"

export default {
  name: "PortalBullet",
  props: ['mdiIconName', 'color', 'size', 'tooltip'],
  computed: {
    portalIcon() {
      return portalIcons.icons[this.mdiIconName]
    }
  }
}
</script>

<style scoped>

</style>
