export default class SearchQueryValue {
  type = ''
  searchValues = []
  properties = {}

  constructor(type, searchValues, properties = {}) {
    this.type = type
    this.searchValues = searchValues
    this.properties = properties
  }
}
