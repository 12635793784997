import remoteApi from "../services/remoteApi"
import localApi from "../services/localApi"

export const config = {
  namespaced: true,
  state: {
    base: {
      // authenticationType: null,
      // baseUrl: '',
      // portal: '',
    },

    server: {},
    local: {
      ui: {
        showLeftSideBar: false,
        showRightSideBar: false,
        dialogs: {
          quickSearch: false,
          assetPreview: false,
        }
      }
    }
  },
  mutations: {
    SET_CONFIG(state, serverConfig) {
      state.server = serverConfig;
    },

    SET_VIEW_TYPE(state, type) {
      state.server.portalUIConfiguration.viewType = type
    },

    TOGGLE_LEFT_SIDEBAR(state) {
      state.local.ui.showLeftSideBar = !state.local.ui.showLeftSideBar
    },

    SHOW_LEFT_SIDEBAR(state, value) {
      state.local.ui.showLeftSideBar = value
    },
  },
  actions: {
    loadExtendedConfig: ({commit}) => {
      return new Promise((resolve) => {
        remoteApi.loadExtendedSettings().then(response => {
          commit('SET_CONFIG', response)
          commit('SHOW_LEFT_SIDEBAR', response.portalUIConfiguration.navigationSideBarInitiallyVisible)
          resolve(true)
        }).catch(() => {
          resolve(false)
        })
      })
    }
  },
  getters: {
    maxBasketItems: (state) => {
      return state.server.basketConfiguration.basketItemLimit
    },
    assetTileContainsImage: (state) => {
      if (state.server.portalUIConfiguration !== undefined) {
        return state.server.portalUIConfiguration.assetTileContainsImage
      }
      return false
    },

    showTermsOfUse: (state) => {
      if (state.server.portalUIConfiguration.showTermsOfUse) {
        const version = state.server.termsOfUseConfiguration.version
        const acceptType = state.server.termsOfUseConfiguration.acceptType
        return localApi.showTermsOfUse(acceptType, version)
      }
      return false
    },

    authenticationType: state => {
      return state.base.authenticationType
    }
  }
};
