import remoteApi from '../services/remoteApi'
import localApi from "../services/localApi"

export const main = {
  namespaced: true,

  state: {
    baseUrl: '',
    portal: '',
    authenticationType: null,
    externalAuthenticationUrl: '',
    authenticated: false,
    authenticatedUser: {
      userName: '',
      displayName: ''
    }
  },

  mutations: {

    SET_USER_NAME(state, username) {
      state.authenticatedUser.username = username
    },

    SET_DISPLAY_NAME(state, displayName) {
      state.authenticatedUser.displayName = displayName
    },
    SET_AUTHENTICATED(state, authenticated) {
      state.authenticated = authenticated
    },

    SET_AUTHENTICATION_TYPE(state, authenticationType) {
      state.authenticationType = authenticationType
    },

    SET_BASE_URL(state, baseUrl) {
      state.baseUrl = baseUrl
    },

    SET_PORTAL(state, portal) {
      state.portal = portal
    },

    SET_EXTERNAL_AUTHENTICATION_URL(state, externalAuthenticationUrl) {
      state.externalAuthenticationUrl = externalAuthenticationUrl
    },
  },

  actions: {
    refresh: ({commit}, initUser) => {
      return remoteApi.refreshToken().then(response => {
        if (response.data.result === "success") {
          let accessToken = response.headers['token']
          remoteApi.setToken(accessToken)
          localApi.storeRefreshToken(response.data.token)
          commit('SET_AUTHENTICATED', true)
          if (initUser) {
            commit('SET_USER_NAME', "response.data.")
            commit('SET_DISPLAY_NAME', "response.data.")
          }
          return true
        }
        commit('SET_AUTHENTICATED', false)
        return false
      })
    },
    login: async ({commit}, credentials) => {
      let response = await remoteApi.authenticate(credentials)
      if (response.data.result === "success") {
        let accessToken = response.headers['token']
        remoteApi.setToken(accessToken)
        localApi.storeRefreshToken(response.data.token)
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER_NAME', response.data.userName)
        commit('SET_DISPLAY_NAME', response.data.displayName)
        return true
      }
      return false
    },

    logout: ({commit}) => {
      remoteApi.clearToken()
      localApi.removeToken();
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER_NAME', '')
      commit('SET_DISPLAY_NAME', '')
    },
  },

  getters: {
    anonymous: state => {
      return state.authenticationType === "anonymous"
    },

    authenticated: state => {
      return state.authenticated
    },

    logoUrl: state => {
      return state.baseUrl + "api/c/" + state.portal + "/logo"
    },

    imageBaseUrl: state => {
      return state.baseUrl + "api/c/" + state.portal + "/image/"
    },
  }
}
