<template>
  <v-layout>
    <v-dialog v-model="visible" :fullscreen="fullScreen" width="90%" min-height="200" @keydown.esc="close" scrollable max-width="1400">
      <v-card>
        <v-card-title class="">
          <span>{{ $t('basketTitle', {numItems: assets.length}) }}</span>
          <v-spacer></v-spacer>
          <icon-button portal-icon-id="portal.closeDialog" :tooltip="$t('tooltipCloseDialog')" @click="close"/>
        </v-card-title>
        <v-card-text>
          <template v-if="loaded">
            <asset-row style="background-color: #efefef">
              <asset-row-column cols="3" sm="2" md="2" lg="2" xl="2"/>
              <asset-row-column cols="4" v-if="$vuetify.breakpoint.smAndUp"/>
              <asset-row-column cols="7" sm="4" md="4" lg="4" xl="4">
                <v-select style="font-size: 14px" :disabled="basketIsEmpty" v-model="downloadFormat"
                          :items="downloadFormats" item-text="name" item-value="id" dense flat
                          @input="applyDownloadFormat(downloadFormat)"/>
              </asset-row-column>
              <asset-row-column cols="2" column-class="text-right">
                <v-spacer/>
                <icon-button portal-icon-id="basket.clearBasket" :disabled="basketIsEmpty" :top="true"
                             :tooltip="$t('tooltipClearBasket')" @click="clearBasket"/>
              </asset-row-column>
            </asset-row>
            <asset-row :top-divider="false">
              <asset-row-column cols="3" sm="2" md="2" lg="2" xl="2" column-class="font-weight-bold">
                {{ $t('basketThumb') }}
              </asset-row-column>
              <asset-row-column cols="4" column-class="font-weight-bold" v-if="$vuetify.breakpoint.smAndUp">
                {{ $t('basketAssetName') }}
              </asset-row-column>
              <asset-row-column cols="7" sm="4" md="4" lg="4" xl="4" column-class="font-weight-bold">
                {{ $t('basketDownloadFormat') }}
              </asset-row-column>
              <asset-row-column cols="2" column-class="font-weight-bold text-right">
                <v-spacer></v-spacer>
                {{ $t('basketActions') }}
              </asset-row-column>
            </asset-row>
            <div v-for="assetItem in assets" :key="assetItem.id">
              <asset-row :top-divider="false">
                <asset-row-column cols="3" sm="2" md="2" lg="2" xl="2">
                  <asset-item-preview min-width="80" max-height="80" max-width="80" height="80" :contain="true"
                                      :asset-item="assetItem" type="THUMBNAIL_URL"/>
                </asset-row-column>
                <asset-row-column cols="4" v-if="$vuetify.breakpoint.smAndUp">
                  <asset-item-property :asset-item="assetItem" name="ASSET_NAME"/>
                </asset-row-column>
                <asset-row-column cols="7" sm="4" md="4" lg="4" xl="4">
                  <v-select style="font-size: 14px" v-model="downloads[assetItem.id]" :items="downloadFormats"
                            item-text="name" item-value="id" dense flat/>
                </asset-row-column>
                <asset-row-column cols="2">
                  <v-spacer/>
                  <icon-button portal-icon-id="basket.removeFromBasket" :top="true"
                               :tooltip="$t('tooltipRemoveFromBasket')" @click="removeFromBasket(assetItem)"/>
                </asset-row-column>
              </asset-row>
            </div>
          </template>
          <template v-else>
            <v-progress-linear :indeterminate="true"></v-progress-linear>
          </template>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="addResultToBasket" :disabled="basketFull" color="primary"
                 style="padding: 4px 10px 0 10px;" small v-if="config.allowAddSearchResultToBasket"
                 @click="addSearchResultToBasket">
            {{ $t('addSearchResultToBasket') }}
          </v-btn>
          <v-btn color="primary" style="padding: 4px 10px 0 10px;" small :disabled="basketIsEmpty"
                 v-if="config.allowCreatePortalDownload" @click="portalDownloadDialog = true">
            {{ $t('createPortalDownload') }}
          </v-btn>
          <v-btn color="primary" style="padding: 4px 10px 0 10px;" small :disabled="basketIsEmpty"
                 :loading="prepareDownload" v-if="config.allowBasketDownload"
                 @click="downloadAssets">{{ $t('basketDownload') }}
          </v-btn>
          <v-btn color="primary" style="padding: 4px 10px 0 10px;" small :disabled="basketIsEmpty"
                 v-if="config.allowMailDownload" @click="openMailDownloadDialog">
            {{ $t('basketMailDownload') }}
          </v-btn>
          <v-btn color="primary" style="padding: 4px 10px 0 10px;" small text :disabled="basketIsEmpty"
                 v-if="config.allowBasketOrder">{{ $t('basketOrderBasket') }}
          </v-btn>
          <!--          <v-btn small color="primary" @click="close">{{$t('btnClose')}}</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Email Download Dialog   -->
    <v-dialog v-if="config.allowMailDownloadFieldConfig" v-model="emailDialog" persistent max-width="500">
      <v-card v-if="prepareEmailStatus === ''">
        <v-card-title class="headline">{{ $t('mailDownloadDialogEnterEMail') }}</v-card-title>
        <v-card-text>{{ $t('mailDownloadDialogVerificationEmailSending') }}</v-card-text>
        <v-card-text>
          <v-form ref="mailDownloadForm" :lazy-validation="true">
            <v-text-field :autofocus="true" ref="targetMail"
                          :rules="[emailAddress.rules.required, emailAddress.rules.email]" v-model="emailAddress.model"
                          :label="$t('mailDownloadDialogYourEMailAddress')"></v-text-field>
            <v-text-field v-if="config.allowMailDownloadFieldConfig.showAdditionalField" ref="additionalField"
                          :rules="[additionalField.rules.required]" v-model="additionalField.model"
                          :label="$t('additionalFieldName')"></v-text-field>
            <v-checkbox v-if="config.allowMailDownloadFieldConfig.showAcceptTermsOfUse" ref="acceptTermsOfUse"
                        :rules="[acceptTermsOfUse.rules.required]" v-model="acceptTermsOfUse.model"
                        :label="$t('checkboxName')">
              <template v-slot:label v-if="config.allowMailDownloadFieldConfig.termsOfUseLink !== ''">
                <a :href="$t('termsOfUseLink')" target="_blank" @click.stop="">{{ $t('checkboxName') }}</a>
              </template>
            </v-checkbox>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="emailDialog = false">{{ $t('mailDownloadDialogButtonCancel') }}</v-btn>
          <v-btn color="primary" text @click="requestAssetDownload">{{ $t('mailDownloadDialogButtonSubmit') }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="prepareEmailStatus === 'PROCESSING'">
        <v-card-title class="headline">{{ $t('mailDownloadDialogProcessingRequest') }}</v-card-title>
        <v-card-text>
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
      <v-card v-else-if="prepareEmailStatus === 'SUCCESS'">
        <v-card-title class="headline">{{ $t('mailDownloadDialogSuccess') }}</v-card-title>
        <v-card-text>{{ $t('mailDownloadDialogCheckInbox') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeAndResetMailDialog">{{ $t('mailDownloadDialogClose') }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="prepareEmailStatus === 'ERROR'">
        <v-card-title class="headline">{{ $t('mailDownloadDialogError') }}</v-card-title>
        <v-card-text>{{ $t('mailDownloadDialogErrorOccurred') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeAndResetMailDialog">{{ $t('mailDownloadDialogClose') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <create-portal-download-dialog :show="portalDownloadDialog" @update-show="portalDownloadDialog = !portalDownloadDialog " v-if="config.allowCreatePortalDownload" :download-formats="downloadFormats"></create-portal-download-dialog>
  </v-layout>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import AssetRow from "../../components/AssetRow"
import remoteApi from "../../services/remoteApi"
import AssetRowColumn from "../../components/AssetRowColumn"
import AssetItemPreview from "../../components/AssetItemPreview"
import AssetItemProperty from "../../components/AssetItemProperty"
import IconButton from "../../components/IconButton"
import CreatePortalDownloadDialog from "@/components/dialog/CreatePortalDownloadDialog"

export default {
  components: {CreatePortalDownloadDialog, IconButton, AssetItemProperty, AssetItemPreview, AssetRowColumn, AssetRow},
  data() {
    return {
      iosDownload: false,
      iosDownloadLink: '',
      visible: true,
      loaded: false,
      downloads: {},
      downloadFormats: [],
      downloadSrc: '',
      prepareDownload: false,
      emailDialog: false,
      portalDownloadDialog:false,
      addResultToBasket: false,
      emailAddress: {
        model: '',
        rules: {
          required: value => !!value || this.$t('mailDownloadDialogRequired'),
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || this.$t('mailDownloadDialogInvalidEMail')
          },
        }
      },
      additionalField: {
        model: '',
        rules: {
          required: value => !!value || this.$t('mailDownloadDialogRequired'),
        }
      },
      acceptTermsOfUse: {
        model: false,
        rules: {
          required: value => !!value || this.$t('mailDownloadDialogRequired'),
        }
      },
      prepareMailDownload: false,
      prepareEmailStatus: '',
      downloadFormat: {}
    }
  },
  methods: {
    ...mapActions({
      'pushSuccessNotification': 'notification/pushSuccessNotification',
      'pushInfoNotification': 'notification/pushInfoNotification',
      'pushErrorNotification': 'notification/pushErrorNotification',
      'addAsset': 'basket/add',
      'removeAsset': 'basket/remove',
      'removeAll': 'basket/removeAll',
      'loadAssets': 'assets/loadAssets',
      'assetSearch': 'assets/assetSearch',
      'loadSearchResult': 'assets/loadSearchResult'
    }),

    close() {
      this.$router.push({name: 'Home'})
    },

    openMailDownloadDialog() {
      this.emailDialog = true
    },

    async downloadAssets() {
      this.prepareDownload = true
      try {
        let response = await remoteApi.prepareBasketDownload(this.downloads) //.then(response => {
        remoteApi.executeZipDownload(response.downloadToken)
        setTimeout(() => {
          this.prepareDownload = false
        }, 1000)
      } catch {
        this.pushErrorNotification("Download Error")
        this.prepareDownload = false
      }
    },

    async addSearchResultToBasket() {
      this.addResultToBasket = true
      let BreakException = {};
      try {

        let assets = await this.loadSearchResult(this.config.basketItemLimit)
        assets.forEach(asset => {
          this.addAsset(asset)
          if (this.assets.length > this.config.basketItemLimit - 1) throw BreakException
        })
      } catch (e) {
        if (e === BreakException) {
          this.pushInfoNotification(this.$t("basketIsFull"))
        } else {
          this.pushErrorNotification("Server error")
        }
      } finally {
        await this.loadDownloadFormats()
        this.addResultToBasket = false
      }
    },

    async loadDownloadFormats() {
      this.loaded = false
      if (this.$store.state.basket.assets.length !== 0) {
        let response = await remoteApi.loadDownloadFormats(this.$i18n.locale, this.assets.map(asset => asset.id))
        this.downloadFormats = response.items
        this.applyDownloadFormat(response.items[0].id)
        this.downloadFormat = response.items[0].id
        this.loaded = true
      } else {
        this.loaded = true
      }
    },

    closeAndResetMailDialog() {
      this.emailDialog = false
      this.emailAddress.model = ''
      this.additionalField.model = ''
      this.acceptTermsOfUse.model = false
      this.prepareEmailStatus = ''
    },

    requestAssetDownload() {
      if (this.$refs.mailDownloadForm.validate()) {
        this.prepareEmailStatus = "PROCESSING";
        remoteApi.prepareMailDownload(this.$i18n.locale, {
          email: this.emailAddress.model,
          info: this.additionalField.model
        }, this.downloads).then(response => {
          if (response.success) {
            this.prepareEmailStatus = "SUCCESS";
          } else {
            this.prepareEmailStatus = "ERROR";
          }
        }).catch(() => {
          this.prepareEmailStatus = "ERROR";
        })
      }
    },

    removeFromBasket(assetItem) {
      this.removeAsset(assetItem)
      delete this.downloads[assetItem.id]
    },

    applyDownloadFormat(downloadFormat) {
      this.assets.map(a => a.id).forEach(id => {
        this.downloads[id] = downloadFormat
      })
    },

    clearBasket() {
      this.assets.map(asset => asset.id).forEach(assetId => {
        delete this.downloads[assetId]
      })
      this.removeAll()
    },
  },
  computed: {

    ...mapState({
      config: state => state.config.server.basketConfiguration,
      baseUrl: state => state.main.baseUrl,
      searchResultAssets: state => state.assets.assets
    }),

    ...mapGetters({
      'assets': 'basket/assets',
      'basketFull': 'basket/basketFull',
      'inBasket': 'basket/inBasket'
    }),

    basketIsEmpty() {
      return this.assets.length === 0 || this.downloadFormats.length === 0
    },

    fullScreen() {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  watch: {
    visible(val) {
      //Close on Backdrop click/touch
      if (val === false) {
        this.close()
      }
    }
  },
  created() {
    this.loadDownloadFormats()
  }
}
</script>
<style lang="css">

</style>
