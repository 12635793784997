<template>
  <v-dialog v-if="visible" v-model="visible" width="600" max-width="600" max-height="800">
    <v-card>
      <v-form v-model="validSearch" @submit.prevent="executeSearch">
        <v-card-title class="py-0">
          <!--        TODO Alternate component appraoch with combobox chips, this is not just a drop in replacement but need more time !!! -->
<!--                  <v-combobox-->
<!--                      ref="quicksearch"-->
<!--                      prepend-icon="mdi-magnify"-->
<!--                      label="Search"-->
<!--                      v-model="chips"-->
<!--                      :items="items"-->
<!--                      :rules="rules"-->
<!--                      chips-->
<!--                      clearable-->
<!--                      multiple-->
<!--                      solo>-->
<!--                    <template v-slot:selection="{ attrs, item, select, selected }">-->
<!--                      <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="remove(item)">-->
<!--                        <strong>{{ item }}</strong>&nbsp;-->
<!--                      </v-chip>-->
<!--                    </template>-->
<!--                  </v-combobox>-->

          <v-text-field :autofocus="false" ref="quicksearch" label="Search" v-model="search" :rules="[searchInputValidationRules]">
						<template slot="prepend">
							<portal-icon portal-icon-id="quickSearch.topSearch"/>
						</template>
					</v-text-field>
          <v-spacer></v-spacer>
          <icon-button portal-icon-id="portal.closeDialog" :tooltip="$t('tooltipCloseDialog')" @click="close"/>
        </v-card-title>
      </v-form>
      <v-divider/>
      <v-card-text>
        <v-card-title class="pb-0">{{$t('searchRecentSearches')}}</v-card-title>
        <v-list>
          <v-list-item @click="executeRecentSearch(recentSearch)" v-for="recentSearch in recentSearches"
                       :key="recentSearch.id" selectable>

            <v-list-item-content>
              <v-list-item-title>
                <v-chip small v-for="(term,index) in recentSearch.value" :key="index" class="ma-2" color="primary">{{term}}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <icon-button portal-icon-id="quickSearch.deleteSearch" @click="deleteSearch(recentSearch)" :tooltip="$t('searchDeleteSearch')"/>
            </v-list-item-action>

            <v-list-item-action class="searchEntry">
              <icon-button portal-icon-id="quickSearch.executeSearch" @click="executeRecentSearch(recentSearch)"
                           :tooltip="$t('searchExecuteSearch')"/>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-btn color="primary" text small @click="clearSearchHistory()">{{$t('searchClearSearchHistory')}}</v-btn>
        <v-spacer></v-spacer>
<!--        <v-btn color="primary" text @click="close">{{$t('btnClose')}}</v-btn>-->
        <v-btn :disabled="!validSearch" color="primary" style="padding: 4px 10px 0 10px;" small @click="executeSearch">{{$t('btnSearch')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

  import {mapActions, mapGetters, mapState} from "vuex"
  import IconButton from "../../components/IconButton"
	import PortalIcon from "../../components/PortalIcon";

  export default {
    components: {PortalIcon, IconButton},
    data() {
      return {
        search: '',
        visible: false,
        validSearch: false,
        //Deactivated, needed for new component
        // chips:[],
        // items:[],
        // rules: [
        //   //TODO i18n messages
        //   value => !!value || 'Required.',
        //   value => (value || '').length > 2 || "Search is 3 chars minimum",
        //   value => (value || '').length <= 80 || 'Max 80 characters',
        //   value => {
        //     const pattern = /^[a-zA-Z0-9\s-]+$/
        //     return pattern.test(value) || 'Only numbers and chars'
        //   },
        // ]
      }
    },
    methods: {
      ...mapActions({
        'assetSearch': 'assets/loadAssets'
      }),
      //Deactivated, needed for new component
      // remove(item) {
      //   this.chips.splice(this.chips.indexOf(item), 1)
      //   this.chips = [...this.chips]
      // },
      searchInputValidationRules(value){
        if(value.length < this.config.minimumInputLength){
          return this.$t('searchInputNotLongEnough', { numChars: this.config.minimumInputLength })
        }
        if(value.length > this.config.maximumInputLength){
          return this.$t('searchInputTooLong', { numChars: this.config.maximumInputLength })
        }
        const pattern = new RegExp(this.config.inputRegularExpression)
        return pattern.test(value) || this.$t('searchInputNotValid')
      },
      executeRecentSearch(recentSearch) {
        this.$store.commit('search/ADD_SEARCH', recentSearch.value)
        this.close()
      },

      executeSearch() {
        if(!this.validSearch) return
        let terms = this.search.split(" ");
        this.$store.commit('search/ADD_SEARCH', terms)
        this.close()
      },

      deleteSearch(recentSearch) {
        this.$store.commit('search/DELETE_SEARCH', recentSearch)
      },

      clearSearchHistory() {
        this.$store.commit('search/RESET_RECENT_SEARCHES')
        this.$refs.quicksearch.focus()
      },

      close() {
        this.visible = false
        this.$router.push({name: 'Home'})
      },
    },
    computed: {
      ...mapGetters({
        'recentSearches': 'search/recentSearches'
      }),
      ...mapState({
        config: state => state.config.server.portalUIConfiguration.quickSearchConfiguration
      }),
    },
    mounted() {
      this.visible = true
    },
    watch: {
      visible(val) {
        //Close on Backdrop click/touch
        if (val === false) {
          this.visible = false
          this.close()
        }
        if (val === true) {
          //The only way to get focus on all browsers
          setTimeout(() => this.$refs.quicksearch.focus(), 50)
        }
      }
    }
  }
</script>
<style lang="css">
  /*.v-dialog {*/
  /*  position: absolute;*/
  /*  top: 0;*/
  /*}*/

  .searchEntry {
    min-width: 24px
  }
</style>
