export const basket = {
  namespaced: true,
  state: {
    assets: [],
    downloadFormats: [],
    selectedFormat: '',
    downloadUrl: '',
    modalVisible: false
  },
  mutations: {
    setSelectedFormat: (state, format) => {
      state.selectedFormat = format;
    },
    setFormats: (state, formats) => {
      state.downloadFormats = formats;
    },
    setDownloadUrl: (state, downloadUrl) => {
      state.downloadUrl = downloadUrl;
    },
    add: (state, asset) => {
      if (state.assets.map(asset => asset.id).indexOf(asset.id) === -1) {
        state.assets.push(asset);
      }
    },
    remove: (state, asset) => {
      const index = state.assets.map(asset => asset.id).indexOf(asset.id);
      if (index !== -1) {
        state.assets.splice(index, 1);
      }
    },
    empty: (state) => {
      state.assets = [];
    },
    SHOW_BASKET: (state, value) => {
      state.modalVisible = value;
    }

  },
  getters: {
    basketFull: (state, getters, rootState, rootGetters) => {
      return state.assets.length >= rootGetters["config/maxBasketItems"]
    },
    assetCount: (state) => {
      return state.assets.length;
    },
    assets: (state) => {
      return state.assets;
    },
    formats: (state) => {
      return state.downloadFormats;
    },
    downloadUrl: (state) => {
      return state.downloadUrl;
    },
    selectedFormat: (state) => {
      return state.selectedFormat;
    },
    inBasket: state => assetItem => {
      return state.assets.map(asset => asset.id).indexOf(assetItem.id) !== -1
    }
  },
  actions: {
    selectedFormat: (context, format) => {
      context.commit('setSelectedFormat', format);
    },
    add: async (context, asset) => {
      if (context.state.assets.length < context.rootState.config.server.basketConfiguration.basketItemLimit) {
        context.commit('add', asset)
        return true
      }
      return false
    },
    remove: (context, asset) => {
      context.commit('remove', asset);
    },
    removeAll: (context) => {
      context.commit('empty');
    },
  }
};
