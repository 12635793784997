import remoteApi from "../services/remoteApi"
import AssetContainer from "../model/AssetContainer"
import {i18n} from '../i18n/i18n.js'

export const container = {
  namespaced: true,

  state: {
    breadcrumbs: [],
    containers: [],
    selectedContainer: {id: ''},
    pages: 0,
    page: 0,
    loading: false
  },


  mutations: {
    SELECTED_CONTAINER: (state, container) => {
      state.selectedContainer = container
    },

    setContainers: (state, containers) => {
      state.containers = containers
    },

    setPages: (state, pages) => {
      state.pages = pages
    },

    setPage: (state, page) => {
      state.page = page
    },

    pushContainers: (state, containers) => {
      state.containers.push(containers)
    },

    pushBreadcrumb: (state, container) => {
      state.breadcrumbs.push(container)
    },

    popBreadcrumb: (state) => {
      if (state.breadcrumbs.length > 0) {
        state.breadcrumbs.pop()
      }
    },
    RESET_BREADCRUMBS: (state) => {
      state.breadcrumbs.length = 0
      state.selectedContainer = {id: ''}
    },
    changeLoadingState: (state) => {
      state.loading = !state.loading
    }
  },

  actions: {
    loadParent: ({getters, commit}) => {
      let parentId = getters.currentContainer.parentId()
      commit('changeLoadingState')
      commit('popBreadcrumb')
      remoteApi.loadAssetContainers(parentId, 0, i18n.locale).then(data => {
        commit('setContainers', data.items.map(i => new AssetContainer(i)))
        commit('setPages', data.pages)
        commit('setPage', data.page)
        commit('changeLoadingState')
      })
    },
    loadContainers: ({commit}, container) => {
      commit('changeLoadingState')
      if (!container.root) {
        commit('pushBreadcrumb', container)
      }
      let containerNavigationIdentifier = container.id
      remoteApi.loadAssetContainers(containerNavigationIdentifier, 0, i18n.locale).then(data => {
        commit('setContainers', data.items.map(i => new AssetContainer(i)))
        commit('setPages', data.pages)
        commit('setPage', data.page)
        commit('changeLoadingState')
      })
    },

    loadNextContainers: ({getters, commit}, containerId) => {
      let nextPage = getters.page++
      commit('changeLoadingState')
      remoteApi.loadAssetContainers(containerId, nextPage).then(data => {
        commit('pushContainers', data.items)
        commit('setPages', data.pages)
        commit('setPage', data.page)
        commit('changeLoadingState')
      })
    }
  },

  getters: {
    pages: state => {
      return state.pages
    },
    page: state => {
      return state.page
    },
    currentContainer: state => {
      return state.breadcrumbs[state.breadcrumbs.length - 1]
    },
  }
}
