<template>
  <v-dialog v-if="config.allowCreatePortalDownload" v-model="_show" persistent max-width="500">
    <v-card v-if="preparePortalDownloadStatus === ''">
      <v-card-title class="headline">{{ $t('createPortalDownloadTitle') }}</v-card-title>
      <v-card-text>{{ $t('createPortalDownloadSubtitle') }}</v-card-text>
      <v-card-text>
        <v-form ref="createPortalDownload" :lazy-validation="true">
          <!--            <v-text-field :autofocus="true" ref="targetMail"-->
          <!--                          :rules="[emailAddress.rules.required, emailAddress.rules.email]" v-model="emailAddress.model"-->
          <!--                          :label="$t('mailDownloadDialogYourEMailAddress')"></v-text-field>-->
          <v-text-field :label="$t('portalDownloadName')" :autofocus="true" v-model="portalDownload.model.name"
                        @focus="$event.target.select()"></v-text-field>
          <!--            <v-textarea :label="$t('portalDownloadName')" v-model="portalDownload.model.info"/>-->
          <v-select :label="$t('portalDownloadFormat')" multiple style="font-size: 14px"
                    v-model="portalDownload.model.downloadFormat"
                    :items="downloadFormats" item-text="name" item-value="id" dense flat
                    :rules="portalDownload.downloadFormatRules"
          />

          <v-menu ref="downloadFromDate" v-model="portalDownload.fromDateMenu" :close-on-content-click="false"
                  :return-value.sync="portalDownload.model.fromDate" transition="scale-transition" offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :rules="[fromDate.rules.required, fromDate.rules.beforeToDate]" v-model="formatFromDate"
                            :label="$t('validFrom')" readonly v-bind="attrs" v-on="on"/>
            </template>

            <v-date-picker v-model="portalDownload.model.fromDate" no-title scrollable locale="de-DE"
                           @change="validate()">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="portalDownload.fromDateMenu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.downloadFromDate.save(portalDownload.model.fromDate)">OK
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-menu ref="downloadToDate" v-model="portalDownload.toDateMenu" :close-on-content-click="false"
                  :return-value.sync="portalDownload.model.toDate" transition="scale-transition" offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :rules="[toDate.rules.afterFromDate, toDate.rules.required]" v-model="formatToDate"
                            :label="$t('validTo')" readonly v-bind="attrs"
                            v-on="on"/>
            </template>
            <v-date-picker v-model="portalDownload.model.toDate" no-title scrollable @change="validate()">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="portalDownload.toDateMenu = false">{{ $t('pdCancel') }}</v-btn>
              <v-btn text color="primary" @click="$refs.downloadToDate.save(portalDownload.model.toDate)">
                {{ $t('pdOk') }}
              </v-btn>
            </v-date-picker>
          </v-menu>

        </v-form>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeAndResetCreatePortalDialog">{{
            $t('cancelCreatePortalDownloadButton')
          }}
        </v-btn>
        <v-btn color="primary" text @click="createPortalDownload">{{ $t('createPortalDownloadButton') }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else-if="preparePortalDownloadStatus === 'PROCESSING'">
      <v-card-title class="headline">{{ $t('mailDownloadDialogProcessingRequest') }}</v-card-title>
      <v-card-text>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else-if="preparePortalDownloadStatus === 'SUCCESS'">
      <v-card-title class="headline">{{ $t('portalDownloadCreatedTitle') }}</v-card-title>
      <v-card-text>
        <v-text-field ref="urlField" readonly v-model="createPortalDownloadResponse.portalDownloadUrl"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="copyToClipboard()">{{ $t('copyPortalDownloadUrl') }}</v-btn>
        <v-btn color="primary" text @click="closeAndResetCreatePortalDialog">{{ $t('mailDownloadDialogClose') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment"
import {mapActions, mapGetters, mapState} from "vuex"
import remoteApi from "@/services/remoteApi"

export default {
  props: {
    show: {type: Boolean, default: false},
    downloadFormats: {type: Array, default: () => []}
  },
  name: "CreatePortalDownloadDialog",
  data() {
    return {
      preparePortalDownloadStatus: '',
      portalDownloadDialog: false,
      createPortalDownloadResponse:null,
      portalDownload: {
        model: {
          name: 'Download',
          info: '',
          downloadFormat: null,
          fromDate: this.getDate().toISOString().substring(0, 10),
          toDate: this.getDate(7).toISOString().substring(0, 10)
        },
        fromDateMenu: false,
        toDateMenu: false,
        downloadFormatRules: [
          v => !!v || this.$t('noDownloadFormatSelected'),
          v => (!!v && v.length > 0) || this.$t('noDownloadFormatSelected')
        ]
      },
      addResultToBasket: false,
      emailAddress: {
        rules: {
          required: value => !!value || this.$t('mailDownloadDialogRequired'),
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || this.$t('mailDownloadDialogInvalidEMail')
          },
        }
      },
      fromDate: {
        rules: {
          required: value => !!value || this.$t('mailDownloadDialogRequired'),
          beforeToDate: () => moment(this.portalDownload.model.fromDate, "YYYY-MM-DD").isBefore(moment(this.portalDownload.model.toDate, "YYYY-MM-DD")) || this.$t('dateInvalid')
        }
      },
      toDate: {
        rules: {
          required: value => !!value || this.$t('mailDownloadDialogRequired'),
          afterFromDate: () => moment(this.portalDownload.model.toDate, "YYYY-MM-DD").isAfter(moment(this.portalDownload.model.fromDate, "YYYY-MM-DD")) || this.$t('dateInvalid')
        }
      },

    }
  },
  methods: {
    ...mapActions({
      'pushSuccessNotification': 'notification/pushSuccessNotification',
      'pushInfoNotification': 'notification/pushInfoNotification',
      'pushErrorNotification': 'notification/pushErrorNotification',
    }),
    validate() {
      this.$refs.createPortalDownload.validate()
    },

    closeAndResetCreatePortalDialog() {
      this.$emit('update-show', false)
      this.createPortalDownloadResponse = {}
      this.preparePortalDownloadStatus = ''
    },
    createPortalDownload() {
      if (this.$refs.createPortalDownload.validate()) {
        this.prepareEmailStatus = "PROCESSING";
        remoteApi.createPortalDownload(
            this.$i18n.locale,
            this.portalDownload.model,
            this.assets.map(asset => asset.id
            )).then(response => {
          if (response.portalDownloadUrl) {
            this.createPortalDownloadResponse = response
            this.preparePortalDownloadStatus = "SUCCESS";
            this.pushSuccessNotification(this.$t("createPortalDownloadSuccess"))
          } else {
            this.pushErrorNotification(this.$t("createPortalDownloadError"))
          }
        }).catch(() => {
          this.pushErrorNotification(this.$t("createPortalDownloadError"))
        })
      }
    },
    copyToClipboard() {
      let textToCopy = this.$refs.urlField.$el.querySelector('input')
      textToCopy.select()
      document.execCommand("copy")
      window.getSelection().removeAllRanges()
    },
    getDate(offset = 0) {
      let resultDate = new Date()
      resultDate.setDate(new Date().getDate() + offset)
      return resultDate
    }
  },
  computed: {
    ...mapState({
      config: state => state.config.server.basketConfiguration,
      baseUrl: state => state.main.baseUrl,
      searchResultAssets: state => state.assets.assets
    }),
    ...mapGetters({'assets': 'basket/assets'}),
    formatFromDate() {
      return this.portalDownload.model.fromDate ? moment(this.portalDownload.model.fromDate).format("L") : ""
    },

    formatToDate() {
      return this.portalDownload.model.toDate ? moment(this.portalDownload.model.toDate).format("L") : ""
    },
    _show: {
      get: function () {
        return this.show
      },
      set: function (value) {
        this.$emit('update-show', value)
      }
    }
  },
  created() {
    moment.locale(this.$i18n.locale)
  }
}
</script>

<style scoped>

</style>