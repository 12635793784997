//This is a different way to define a module instead of exporting an object
export const namespaced = true;

export const state = {
  notifications: []
}
const PUSH_NOTIFICATION = "PUSH_NOTIFICATION"
const POP_NOTIFICATION = "POP_NOTIFICATION"

export const mutations = {
  [PUSH_NOTIFICATION](state, payload) {
    state.notifications.push(payload)
  },
  [POP_NOTIFICATION](state, index) {
    state.notifications.splice(index, 1)
  },
}

export const getters = {
  notifications: state => {
    return state.notifications
  }
}

export const actions = {
  removeNotification({commit}, notificationIndex) {
    commit(POP_NOTIFICATION, notificationIndex)
  },

  pushNotification({commit}, notification, color) {
    commit(PUSH_NOTIFICATION, {
      date: new Date().getTime(),
      text: notification,
      color: color,
      timeout: 5000,
      show: true
    })
  },

  pushSuccessNotification({commit}, notification) {
    commit(PUSH_NOTIFICATION, {
      date: new Date().getTime(),
      text: notification,
      color: "success",
      timeout: 5000,
      show: true
    })
  },
  pushInfoNotification({commit}, notification) {
    commit(PUSH_NOTIFICATION, {
      date: new Date().getTime(),
      text: notification,
      color: "info",
      timeout: 5000,
      show: true
    })
  },

  pushErrorNotification({commit}, notification) {
    commit(PUSH_NOTIFICATION, {
      date: new Date().getTime(),
      text: notification,
      color: "error",
      timeout: 5000,
      show: true
    })
  },
  pushWarningNotification({commit}, notification) {
    commit(PUSH_NOTIFICATION, {
      date: new Date().getTime(),
      text: notification,
      color: "warning",
      timeout: 5000,
      show: true
    })
  },
}
