<template>
  <component :is="getComponent(properties['textType'])" class="pa-0">
    <div :class="cssClass">
      &nbsp;{{ text }}
    </div>
  </component>
</template>
<script>
import {VCardSubtitle, VCardText, VCardTitle} from 'vuetify/lib'

let elementMap = {
  "TITLE": "v-card-title",
  "SUBTITLE": "v-card-subtitle",
  "TEXT": "v-card-text",
  "undefined": "v-card-text"
}
export default {
  name: 'AssetTileText',
  components: {
    VCardTitle, VCardText, VCardSubtitle
  },
  props: {
    assetItem: {},
    properties: {},
  },
  methods: {
    getComponent(element) {
      return elementMap[element]
    }
  },
  computed: {
    text() {
      if(this.properties['translate']){
        return this.$t(this.properties['text'])
      }
      return this.properties['text']
    },
    cssClass() {
      return this.properties['cssClass']
    }
  }
}
</script>
