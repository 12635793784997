<template>
  <v-content class="login" v-if="needsInteractiveLogin">
    <v-container fluid class="fill-height loginOverlay" :style="bgrStyle">
      <!--      <v-snackbar-->
      <!--          v-model="error"-->
      <!--          :multi-line="true"-->
      <!--          :timeout="6000"-->
      <!--          :top="true"-->
      <!--      >{{errorText}}-->
      <!--      </v-snackbar>-->
      <v-row class="justify-center" style="margin: 0">
        <v-col cols="12" sm="8" md="4" lg="4" xl="4">
          <v-toolbar class="pt-0" color="primary">
            <v-toolbar-title class="white--text">
              <h4>{{$t('loginHeadLine')}}</h4>
            </v-toolbar-title>
          </v-toolbar>
          <v-card>
            <v-card-text v-if="error" class="red--text">{{$t('loginFailed')}}</v-card-text>
            <v-card-text class="pt-4">
              <div>
                <v-form v-model="valid" ref="form">
                  <v-text-field
                      :label="$t('labelUsername')"
                      prepend-icon="mdi-account"
                      v-model="username"
                      :rules="rules.usernameRules"
                      min="8"
                      required/>
                  <v-text-field
                      :label="$t('labelPassword')"
                      v-model="password"
                      prepend-icon="mdi-lock"
                      :rules="rules.passwordRules"
                      :append-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (hidePassword = !hidePassword)"
                      :type="hidePassword ? 'password' : 'text'"
                      required
                      autocomplete="current-password"/>
                  <v-layout>
                    <v-spacer/>
                    <v-btn type="submit" @click.prevent="submit" :disabled="!valid">{{$t('btnLogin')}}</v-btn>
                    <!--<a href="">Forgot Password</a>-->
                  </v-layout>
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <basic-footer/>
  </v-content>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import BasicFooter from "../components/BasicFooter";
  import localApi from "../services/localApi"
  import remoteApi from "../services/remoteApi"

  export default {
    components: {BasicFooter},
    data() {
      return {
        needsInteractiveLogin: false,
        error: false,
        errorText: '',
        valid: true,
        hidePassword: true,
        username: '',
        password: '',
        rules: {
          usernameRules: [
            (v) => !!v || this.$t('userNameRequired')
          ],
          passwordRules: [
            (v) => !!v || this.$t('passwordRequired')
          ]
        }
      }
    },
    methods: {
      ...mapActions({'login': 'main/login'}),
      ...mapState({
        baseUrl: state => state.main.baseUrl,
        portal: state => state.main.portal
      }),
      submit() {
        if (this.valid) {
          this.login({username: this.username, password: this.password}).then(response => {
            if (response) {
              this.$router.push({name: 'Home', params: {portal: this.portal()}})
            }
          }).catch(error => {
            this.errorText = error;
            this.error = true
          })
        }
      }
    },
    computed: {
      disabled() {
        return this.username === '' || this.password === ''
      },

      bgrStyle() {
        return {
          backgroundImage: 'url(' + this.bgImageUrl + ')',
          backgroundSize: 'cover'
        }
      },

      bgImageUrl() {
        return this.baseUrl() + "api/c/" + this.portal() + "/bgr"
      }
    },
    async created() {
      //Refresh token authentication
      if (localApi.hasRefreshToken() && this.$store.state.main.authenticationType !== 'external') {
        let refreshToken = localApi.getRefreshToken()
        remoteApi.setRefreshToken(refreshToken)
        let result = await this.$store.dispatch("main/refresh", true)
        if (result) {
          await this.$router.push({name: 'Home', params: {portal: this.portal()}}).catch(() => {})
          return
        }
      }

      //Transparent anonymous authentication
      if (!this.$store.getters['main/authenticated']  && this.$store.state.main.authenticationType === 'anonymous') {

        let result = await this.$store.dispatch("main/login", {username: localApi.getAnonymousIdentifier()})
        if (result) {
          await this.$router.push({name: 'Home', params: {portal: this.portal()}}).catch(() => {})
          return
        }
      }

      //External authentication: Login token authentication
      if (!this.$store.getters['main/authenticated'] && this.$store.state.main.authenticationType === 'external') {
        const urlParams = new URLSearchParams(window.location.search);
        const loginToken = urlParams.get('token');
        if (loginToken !== null) {
          try {
            let result = await this.login({token: loginToken})
            if (result) {
              await this.$router.push({name: 'Home', params: {portal: this.portal()}}).catch(() => {})
              return
            } else {
              window.location = this.$store.state.main.externalAuthenticationUrl
            }
          } catch (e) {
            window.location = this.$store.state.main.externalAuthenticationUrl
          }
        }
      }

      //External authentication: Refresh token login
      if (localApi.hasRefreshToken() && this.$store.state.main.authenticationType === 'external') {
        let refreshToken = localApi.getRefreshToken()
        remoteApi.setRefreshToken(refreshToken)
        let result = await this.$store.dispatch("main/refresh", true)
        if (result) {
          await this.$router.push({name: 'Home', params: {portal: this.portal()}}).catch(() => {})
          return
        }
      }

      //External authentication: Forwarding to external authentication url
      if (!this.$store.getters['main/authenticated'] && this.$store.state.main.authenticationType === 'external') {
        window.location = this.$store.state.main.externalAuthenticationUrl
      }

      //Enable Interactive authentication as last option
      if (!this.$store.getters['main/authenticated'] && this.$store.state.main.authenticationType === 'backend') {
        this.needsInteractiveLogin = true
      }

    }
  }
</script>
<style>
  html {
    overflow-y: auto;
  }
</style>
