<template>
  <v-img :src="value"
         :contain="contain"
         :height="height"
         :width="width"
         :max-height="maxHeight"
         :max-width="maxWidth"
         :lazy-src="lazySrc"
         @click="click">
    <slot></slot>
  </v-img>
</template>

<script>

export default {
  props: ['assetItem', 'type', 'height', 'contain', 'width', 'maxWidth', 'maxHeight', 'lazySrc'],
  name: "AssetItemPreview",
  computed: {
    value() {
      let imageUrl = this.assetItem.getPropertyValue(this.type)
      let image = new Image()
      image.src = imageUrl
      image.onload = () => {
        this.$emit('imageLoaded', {
          width: image.width,
          height: image.height,
          aspectRatio: image.width / image.height,
          landscape: image.width > image.height,
          portrait: image.height > image.width
        })
      }
      return imageUrl
    }
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>

</style>
