<template>
  <v-dialog v-if="loaded" :fullscreen="fullScreen" width="90%" max-width="1024px" v-model="visible">
    <!--    v-touch="{left: () => swipeLeft(), right: () => swipeRight()}"-->
    <v-card>
      <v-card-title style="padding-bottom: 0">
        <p class="text-left headline">
          <asset-item-property :asset-item="assetItem" name="ASSET_NAME"/>
        </p>
        <v-spacer />
        <icon-button portal-icon-id="portal.closeDialog" :tooltip="$t('tooltipCloseDialog')" @click="close"/>
      </v-card-title>
      <v-divider />
      <v-card-text v-if="loaded">
        <v-row>
          <v-col cols="12" md="5" lg="5" xl="5">
            <v-container>
              <asset-item-preview height="400px" width="400px" :contain="true" :asset-item="assetItem" type="THUMBNAIL_URL"/>
            </v-container>
          </v-col>
          <v-col cols="12" md="7" lg="7" xl="7">
            <v-tabs show-arrows>
              <v-tabs-slider color="primary lighten-1"></v-tabs-slider>
              <v-tab v-for="tab in tabs" :key="tab" >{{$t(tab)}}</v-tab>
              <v-tab-item v-for="tab in tabs" :key="tab">
                <v-simple-table v-if="tab === 'detailTabProperties'" fixed-header dense :height="$vuetify.breakpoint.height/2+50">
                  <thead>
                  <tr>
                    <th class="text-left">{{$t('detailTabName')}}</th>
                    <th class="text-left">{{$t('detailTabValue')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="loaded">
                    <tr v-for="property in assetItem.allDisplayPropertyIdentifiers()" v-bind:key="property">
                      <td class="text-left font-weight-medium property-key">
                        <asset-item-property-label :value="property"/>
                      </td>
                      <td class="text-left">
                        <asset-item-property :asset-item="assetItem" :name="property"/>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </v-simple-table>
                <v-simple-table v-else-if="tab !=='detailTabProperties'" fixed-header dense :height="$vuetify.breakpoint.height/2+50">
                  <thead>
                  <tr>
                    <th class="text-left">{{$t('detailTabName')}}</th>
                    <th class="text-left">{{$t('detailTabValue')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="loaded">
                    <tr v-for="field in assetItemFields(tab)" :key="field.name">
                      <td class="text-left font-weight-medium  property-key">
                        <asset-item-field-label :field="field" suffix=":"/>
                      </td>
                      <td class="text-left">
                        <asset-item-field :field="field"/>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </v-simple-table>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="!hasPrevious" small @click="previousAsset">{{$t('btnPrevious')}}</v-btn>
        <v-btn :disabled="!hasNext" small @click="nextAsset">{{$t('btnNext')}}</v-btn>
<!--        <v-btn color="primary darken-1" @click="close">{{$t('btnClose')}}</v-btn>-->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mapActions, mapState} from "vuex"
  import AssetItemPreview from "../../components/AssetItemPreview";
  import AssetItemProperty from "../../components/AssetItemProperty";
  import AssetItemPropertyLabel from "../../components/AssetItemPropertyLabel"
  import AssetItemFieldLabel from "../../components/AssetItemFieldLabel"
  import AssetItemField from "../../components/AssetItemField"
  import IconButton from "../../components/IconButton";

  export default {
    name: "AssetDetailModal",
    components: {AssetItemField, AssetItemFieldLabel, AssetItemPropertyLabel, AssetItemProperty, AssetItemPreview,IconButton},
    data() {
      return {
        visible: false,
        currentAssetIndex: -1,
        loaded: false,
        tabs:[]
      }
    },
    created() {
      this.visible = true
      let assetId = this.$route.params.assetId
      this.localLoadAsset(assetId)
    },
    methods: {
      ...mapActions({
        'loadAsset': 'assets/loadAsset',
        'loadAssets': 'assets/loadAssets'
      }),
      assetItemFields(fieldGroup){
        return this.assetItem.entity.entityFields.filter( field => field.fieldGroup === fieldGroup)
      },
      swipeLeft() {
        this.nextAsset()
      },
      swipeRight() {
        this.previousAsset()
      },
      localLoadAsset(assetId) {
        this.currentAssetIndex = this.assets.findIndex(asset => asset.id === assetId)
        // if (this.assets.length - 3 < this.currentAssetIndex) {
        //     this.loadAssets()
        // }
        this.loadAsset(assetId).then(() => {
            this.tabs = []
            this.tabs.push("detailTabProperties")
            this.assetItem.entity.entityFieldGroups.forEach(item => this.tabs.push(item))
          this.visible = true
          this.loaded = true
        })
      },
      nextAsset() {
        let assetId = this.assets[this.currentAssetIndex + 1].id
        this.localLoadAsset(assetId)
        this.$router.push("/"+this.portal+"/home/asset/" + this.assets[this.currentAssetIndex + 1].id)
      },
      previousAsset() {
        let assetId = this.assets[this.currentAssetIndex - 1].id
        this.localLoadAsset(assetId)
        this.$router.push("/"+this.portal+"/home/asset/" + assetId)
      },
      close() {
        this.visible = false
        this.$router.push({name:'Home'})
      }
    },
    computed: {
      ...mapState({
        portal: state => state.main.portal,
        assetItem: state => state.assets.selectedAsset,
        assets: state => state.assets.assets
      }),
      fullScreen() {
        return this.$vuetify.breakpoint.smAndDown
      },
      hasPrevious() {
        return this.currentAssetIndex > 0;
      },
      hasNext() {
        return this.currentAssetIndex !== this.assets.length - 1
      },
    },
    watch: {
      visible(val) {
        //Close on Backdrop click/touch
        if (val === false) {
          this.visible = false
          this.close()
        }
      }
    }
  }
</script>

<style scoped>
  .headline {
    font-size: medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .property-key {
    width: 200px;
		white-space: nowrap;
  }
</style>
