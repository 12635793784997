<template>
  <v-card-actions>
    <v-spacer/>
    <icon-button v-if="uiConfig.enableImagerImageUrlButton" portal-icon-id="tile.actionButton.imagerCopyUrlDialog"
                 :top="true" :tooltip="$t('toolTipImagerUrlCopyDialog')"
                 @click="$emit('showImagerUrlDialog', assetItem)"/>
    <icon-button portal-icon-id="tile.actionButton.showDetailView" :top="true" :tooltip="$t('tooltipShowDetailView')"
                 @click="$emit('showDetail',assetItem)"/>
    <icon-button portal-icon-id="tile.actionButton.downloadAsset" v-if="basketConfig.allowDirectDownload" :top="true"
                 :tooltip="$t('tooltipDownloadAsset')" @click="$emit('download',assetItem)"/>
    <template v-if="basketConfig.enableBasket">
      <icon-button :disabled="basketFull" portal-icon-id="tile.actionButton.addToBasket" v-if="!inBasket(assetItem)"
                   :top="true" :tooltip="$t('tooltipAddToBasket')"
                   @click="$emit('addBasket',assetItem)"/>
      <icon-button portal-icon-id="tile.actionButton.removeFromBasket" v-else :top="true"
                   :tooltip="$t('tooltipRemoveFromBasket')"
                   @click="$emit('removeBasket',assetItem)"/>
    </template>
  </v-card-actions>
</template>
<script>
import IconButton from "./IconButton"
import {mapGetters, mapState} from "vuex"

export default {
  name: 'AssetTileActionButtonGroup',
  components: {IconButton},
  props: {
    assetItem: {}
  },
  computed: {
    ...mapState({
      basketConfig: state => state.config.server.basketConfiguration,
      uiConfig: state => state.config.server.portalUIConfiguration,
    }),
    ...mapGetters({
      'inBasket': 'basket/inBasket',
      'basketFull': 'basket/basketFull'
    })
  }
}
</script>
