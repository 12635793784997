export default class BaseEntity {
  id = ''

  constructor(entity) {
    this.entity = entity;
    this.id = this.getPropertyValue('ID')

  }

  name() {
    return this.getPropertyValue('NAME')
  }

  getProperty(propertyIdentifier) {
    let find = this.entity.entityProperties.find(aip => aip.identifier === propertyIdentifier)
    if (find !== undefined) {
      return find
    }
    return {value: '', type: 'STRING'}
  }

  getPropertyValue(propertyIdentifier) {
    let find = this.entity.entityProperties.find(aip => aip.identifier === propertyIdentifier)
    if (find !== undefined) {
      return find.value
    }
    return ''
  }

  allDisplayPropertyIdentifiers() {
    return this.entity.entityProperties.filter(p => p.displayValue === true).map(p => p.identifier)
  }
}
