export default class SearchQuery {
  page = ''
  pageSize = ''
  searchQueryValues = []
  locale = ''

  constructor(page, pageSize, queryValues, locale) {
    this.page = page
    this.pageSize = pageSize
    this.searchQueryValues = queryValues
    this.locale = locale
  }
}
