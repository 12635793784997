<template>
  <v-icon :class="iconClass" :color="iconColor" :style="iconStyle" :size="iconSize">
    {{portalIcon}}
  </v-icon>
</template>

<script>
  import {mapState} from "vuex";
  import localApi from "../services/localApi"
  import portalIcons from "../portal-icons"

  function findIconSettings(settings, settingName, propertyName) {
    let find = settings.find(iconSetting => iconSetting.name === settingName)
    if (find !== undefined) return find[propertyName]
    return "";
  }

  export default {
    props: ['portalIconId'],
    name: "PortalIcon",

    computed: {
      ...mapState({
        iconConfiguration: state => state.config.server.portalUIConfiguration.iconConfiguration
      }),

      portalIcon() {
        let mdiIconName = localApi.toCamelCase(this.iconAssignment.iconName)
        return portalIcons.icons[mdiIconName]
      },

      iconClass() {
        if (this.iconAssignment.iconClass !== undefined && this.iconAssignment.iconClass !== null) {
          return this.iconAssignment.iconClass
        }
        return findIconSettings(this.iconConfiguration.iconSettings, this.iconAssignment.iconSettingName, 'clazz')
      },

      iconColor() {
        if (this.iconAssignment.iconColor !== undefined && this.iconAssignment.iconColor !== null) {
          return this.iconAssignment.iconColor
        }
        return findIconSettings(this.iconConfiguration.iconSettings, this.iconAssignment.iconSettingName, 'color')
      },

      iconStyle() {
        if (this.iconAssignment.iconStyle !== undefined && this.iconAssignment.iconStyle !== null) {
          return this.iconAssignment.iconStyle
        }
        return findIconSettings(this.iconConfiguration.iconSettings, this.iconAssignment.iconSettingName, 'style')
      },

      iconSize() {
        if (this.iconAssignment.iconSize !== undefined && this.iconAssignment.iconSize !== null) {
          return this.iconAssignment.iconSize
        }
        return findIconSettings(this.iconConfiguration.iconSettings, this.iconAssignment.iconSettingName, 'size')
      },

      iconAssignment() {
        let iconAssignment = this.iconConfiguration.iconAssignments.find(iconAssignment => iconAssignment.portalIconId === this.portalIconId)
        if (iconAssignment === undefined) {
          return this.iconConfiguration.iconAssignments.find(iconAssignment => iconAssignment.portalIconId === 'portal.placeHolder')
        }

        return iconAssignment
      }
    }
  }
</script>

<style scoped>

</style>
