import BaseEntity from "./BaseEntity"

export default class AssetContainer extends BaseEntity {
  hasChildren(){
    return this.getPropertyValue('CHILDREN') === 'true'
  }

  parentId(){
    return this.getPropertyValue('PARENT_ID')
  }
  color(){
    return this.getPropertyValue('COLOR')
  }
}
