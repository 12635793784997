<template>
  <v-hover v-slot:default="{ hover }">
    <v-card flat class="largeThumbHover" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="$emit('showPreview', assetItem)" style="border-radius: 0">
        <v-img :src="properties['url']" :contain="containImage" max-height="200" min-height="200" @click="$emit('click')">
        <v-container class="fill-height">
          <v-spacer/>
          <v-btn v-if="hover" icon>
            <portal-icon class="tile-show-detail-ie" v-if="assetItem.isVideo()" portal-icon-id="tile.showVideoPreview"/>
            <portal-icon class="tile-show-detail-ie" v-else portal-icon-id="tile.showImagePreview"/>
          </v-btn>
          <v-spacer/>
        </v-container>
        </v-img>
    </v-card>
  </v-hover>
</template>

<script>
import {mapGetters, mapState} from "vuex"
// import AssetItemPreview from "@/components/AssetItemPreview"
import PortalIcon from "./PortalIcon"

export default {
  name: "AssetTilePreview",
  props: {
    assetItem:{},
    properties:{}
  },
  components: {PortalIcon},
  computed: {
    ...mapState({
      uiConfig: state => state.config.server.portalUIConfiguration,
    }),
    ...mapGetters({
      'containImage':'config/assetTileContainsImage'
    })
  }
}
</script>

<style lang="css" scoped>
.largeThumbHover {
  opacity: 0.6;
  transition: .4s ease-in-out;
}

.largeThumbHover:not(.on-hover) {
  opacity: 1;
}

.show-btn {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
