<template>
  <v-col :cols="cols" :xl="xl" :lg="lg" :sm="sm" :md="md" :class="columnClass">
    <v-container :class="containerClass">
      <slot></slot>
    </v-container>
  </v-col>
</template>

<script>
  export default {
    name: "AssetRowColumn",
    props: {
      cols: {type: [Boolean, String, Number], default: false},
      xl: {type: [Boolean, String, Number], default: false},
      lg: {type: [Boolean, String, Number], default: false},
      sm: {type: [Boolean, String, Number], default: false},
      md: {type: [Boolean, String, Number], default: false},
      containerClass: {type: String, default: 'fill-height'},
      columnClass: {type: String, default: ''}
    }
  }
</script>

<style scoped>

</style>
