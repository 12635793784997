<template>
  <div style="width: 800px;z-index: 100">
    <v-snackbar min-width="800" v-model="n.show" v-for="(n,i) in notifications"
                :key="n.date"
                :style="{'margin-bottom':calculateMargin(i)}"
                :value="true" @input="hide" :color="n.color"
                :timeout="n.timeout" right>
      {{ n.text }}
      <template v-slot:action>
        <v-btn text @click="hide(i)">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"

export default {
  name: 'Notifications',

  data: () => ({
    show: true
  }),

  methods: {
    ...mapActions({'removeNotification': 'notification/removeNotification'}),
    calculateMargin(i) {
      return (i * 60) + 'px'
    },
    hide(i) {
      this.removeNotification(i)
    }
  },
  computed: {
    ...mapGetters({'notifications': 'notification/notifications'})
  }

}
</script>

<style scoped>

</style>
