<template>
  <v-content>
    <v-container fluid class="fill-height loginOverlay" :style="bgrStyle">
      <v-row class="justify-center" style="margin: 0">
        <v-col cols="12" sm="8" md="6" lg="6" xl="6">
          <v-toolbar class="pt-0" color="primary">
            <v-toolbar-title class="white--text"><h4>Maintenance</h4></v-toolbar-title>
          </v-toolbar>
          <v-card>
            <v-card-text class="pt-4">
              <div>
                  <v-row style="font-size: x-large; padding: 15px; line-height: 1.2em">We're undergoing a bit of scheduled maintenance.</v-row>
                  <v-divider/>
                  <v-row style="font-size: small; padding: 15px">Sorry for the inconvenience. We'll be back up and running as fast as possible.
                    <br/><br/>This page will reload automatically after 5 minutes if you leave it open. Or just come back later to see if we are up and running again.</v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <basic-footer color="primary"/>
  </v-content>
</template>

<script>
  import {mapState} from "vuex";
  import BasicFooter from "../components/BasicFooter";

  export default {
    name: "Maintenance",
    components: {BasicFooter},
    data() {
      return {}
    },
    methods: {
      ...mapState({
        baseUrl: state => state.config.base.baseUrl,
        portal: state => state.config.base.portal
      }),
    },
    computed: {
      bgrStyle() {
        return {
          backgroundImage: 'url(' + this.bgImageUrl + ')',
          backgroundSize: 'cover'
        }
      },
      bgImageUrl() {
        return this.baseUrl() + "api/c/" + this.portal + "/bgr"
      }
    },
    created() {
      setTimeout(()=>{
        this.$router.push("/")
      },50000)
    }
  }
</script>

<style>
  html { overflow-y: auto; }
</style>
