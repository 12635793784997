<template>
  <div v-if="fieldType === 'MultiValue'">
    <v-chip v-for="value in values" :key="value[0].id" color="secondary" class="ma-1" small>{{value[0].value}}</v-chip>
  </div>
  <div v-else-if="fieldType ==='Date'">
    <span>{{dateValue}}</span>
  </div>
  <div v-else-if="fieldType ==='Boolean'">
    <v-icon small v-if="checkBoxChecked">mdi-checkbox-marked-circle-outline</v-icon>
    <v-icon small v-else>mdi-checkbox-blank-circle-outline</v-icon>
  </div>
  <div v-else-if="fieldType ==='ComboBox'">
    <span>{{comboBoxValue}}</span>
  </div>
  <div v-else>
    <span>{{rawValue}}</span>
  </div>
</template>

<script>
  //TODO This whole component needs some rework and soe does the server side
  import moment from "moment"
  import {i18n} from "../i18n/i18n"

  export default {
    props: {
      field: {
        type: Object, default() {
          return {}
        }
      },
      suffix: {type: String, default: ''},
      type: {type: String, default: 'Label'}
    },
    data() {
      return {}
    },
    created() {

      // this.values.push()
    },
    computed: {
      fieldType() {
        return this.field.type
      },
      values() {
        if (this.field.type === 'MultiValue') {
          return this.field.fieldValues.map(fv => fv.displayValue)
        }
        return []
      },
      dateValue() {
        if (this.field.type === 'Date') {
          if (this.field && this.field.fieldValues[0]) {
            let value = this.field.fieldValues[0].value
            moment.locale(i18n.locale)
            return moment(parseInt(value)).format("LL")
          }
          return ""
        }
        return this.field
      },
      checkBoxChecked() {
        if (this.field && this.field.fieldValues[0]) {
          return this.field.fieldValues[0].value === true
        }
        return false
      },
      comboBoxValue() {
        if (this.field && this.field.fieldValues[0] && this.field.fieldValues[0].displayValue[0]) {
          return this.field.fieldValues[0].displayValue[0].value
        }
        return ""
      },
      rawValue() {
        if (this.field && this.field.fieldValues[0]) {
          return this.field.fieldValues[0].value
        }
        return ""
      }
    }
  }
</script>

<style scoped>

</style>
