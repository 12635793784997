import {store} from '../store/store'
import remoteApi from "./remoteApi"

const REFRESH_TOKEN_KEY = "TOKEN"
const TERMS_OF_USE_ACCEPTED = 'TERMS_OF_USE_ACCEPTED'
const TERMS_OF_USE_VERSION = 'TERMS_OF_USE_VERSION';
const ANON_IDENTIFIER = "ANON_IDENTIFIER"

function storeLocalValue(key, value) {
  localStorage.setItem(_getLocalStorageKey(key), value)
}

function removeLocalValue(key) {
  localStorage.removeItem(_getLocalStorageKey(key))
}

function loadLocalValue(key) {
  return localStorage.getItem(_getLocalStorageKey(key))
}

function hasLocalValue(key) {
  return loadLocalValue(key) !== null
}

function _getLocalStorageKey(keyFragment) {
  return getPortal() + "_" + keyFragment
}

function getQueryParams(defaultHost) {
  const urlParams = new URLSearchParams(window.location.search);
  const theHost = urlParams.get('host');
  return theHost !== null ? theHost : defaultHost
}

function getPortal() {
  return store.state.main.portal
}

export default {
  toKebabCase(theString) {
    return theString.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join("-")
  },

  toCamelCase(theString) {
    return theString.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (m, char) {
      return char.toUpperCase()
    });
  },

  loadLanguage(i18n, locale) {
    remoteApi.loadLanguage(locale).then(languageData => {
      this.mergeLanguage(i18n, locale, languageData)
    })
  },

  mergeLanguage(i18n, locale, language) {
    i18n.mergeLocaleMessage(locale, language)
  },

  showTermsOfUse(acceptType, version) {
    if (acceptType === "EVERY_LOGIN") {
      removeLocalValue(TERMS_OF_USE_ACCEPTED)
      removeLocalValue(TERMS_OF_USE_VERSION)
      return true
    }
    if (acceptType === "FIRST_LOGIN_AND_VERSION_CHANGE") {
      let acceptedTermsOfUseVersion = loadLocalValue(TERMS_OF_USE_VERSION)
      if (version !== acceptedTermsOfUseVersion) {
        return true;
      }
    }
    return false;
  },
  acceptTermsOfUse(termsOfUse) {
    if (termsOfUse.acceptType !== "EVERY_LOGIN") {
      storeLocalValue(TERMS_OF_USE_ACCEPTED, true)
      storeLocalValue(TERMS_OF_USE_VERSION, termsOfUse.version)
    }
  },

  loadClientDataFromLocalStore() {

  },

  removeToken() {
    removeLocalValue(REFRESH_TOKEN_KEY)
  },

  storeRefreshToken(token) {
    if (token === null) {
      this.removeToken()
    } else {
      storeLocalValue(REFRESH_TOKEN_KEY, token)
    }
  },

  initClient(response) {
    const host = getQueryParams(response.data.server)
    //TODO Try to solve this more vue like
    let strings = window.location.pathname.split('/')
    let portal = ''

    if (strings.length > 1 && strings[1] !== "") {
      portal = strings[1]
    } else {
      portal = response.data.portal
    }

    if (response.data.urlPriority && response.data.urlPriority === false) {
      portal = response.data.portal
    }
    remoteApi.init(host, portal)
    store.commit("main/SET_BASE_URL", host)
    store.commit("main/SET_PORTAL", portal)
    this.loadClientDataFromLocalStore()
  },

  getRandomString(length) {
    let random = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    return random.substr(0, length)
  },

  createGuid() {
    function fourByteHexValue() {
      return Math.random().toString(16).slice(-4);
    }

    return fourByteHexValue() + fourByteHexValue() +
      '-' + fourByteHexValue() +
      '-' + fourByteHexValue() +
      '-' + fourByteHexValue() +
      '-' + fourByteHexValue() + fourByteHexValue() + fourByteHexValue();
  },

  isAuthenticated() {
    return store.getters['main/authenticated']
  },

  getAuthenticationType() {
    return store.getters['main/authenticationType']
  },

  getAnonymousIdentifier() {
    if (hasLocalValue(ANON_IDENTIFIER)) {
      return loadLocalValue(ANON_IDENTIFIER)
    }
    let anonIdentifier = this.createGuid()
    storeLocalValue(ANON_IDENTIFIER, anonIdentifier)
    return anonIdentifier
  },

  hasRefreshToken() {
    return hasLocalValue(REFRESH_TOKEN_KEY)
  },

  getRefreshToken() {
    return loadLocalValue(REFRESH_TOKEN_KEY)
  },

  authenticateOrRefreshAuthentication() {
    // if (this.isAuthenticated()) {
    //   return true;
    // }
    switch (this.getAuthenticationType()) {
      case 'anonymous':
        // if (this.hasRefreshToken()) {
        //   return await store.dispatch("main/refresh", {initUser: true})
        // }
        return store.dispatch("main/login", {username: this.getAnonymousIdentifier()})
      case 'backend':
        break;
      case 'external':
        break;
      default:
        break
    }
  },

  setAuthenticationType(authenticationType) {
    store.commit('main/SET_AUTHENTICATION_TYPE', authenticationType)
  },

  applyTheme($vuetify, theme) {
    $vuetify.theme.dark = theme.dark
    $vuetify.theme.themes.light.primary = theme.themes.light.primary
    $vuetify.theme.themes.light.secondary = theme.themes.light.secondary
    $vuetify.theme.themes.light.tertiary = theme.themes.light.tertiary
    $vuetify.theme.themes.light.accent = theme.themes.light.accent
    $vuetify.theme.themes.light.error = theme.themes.light.error
    $vuetify.theme.themes.light.info = theme.themes.light.info
    $vuetify.theme.themes.light.success = theme.themes.light.success
    $vuetify.theme.themes.light.warning = theme.themes.light.warning

    $vuetify.theme.themes.dark.primary = theme.themes.dark.primary
    $vuetify.theme.themes.dark.secondary = theme.themes.dark.secondary
    $vuetify.theme.themes.dark.tertiary = theme.themes.dark.tertiary
    $vuetify.theme.themes.dark.accent = theme.themes.dark.accent
    $vuetify.theme.themes.dark.error = theme.themes.dark.error
    $vuetify.theme.themes.dark.info = theme.themes.dark.info
    $vuetify.theme.themes.dark.success = theme.themes.dark.success
    $vuetify.theme.themes.dark.warning = theme.themes.dark.warning

    // const themes = ["light", "dark"]
    // themes.forEach(theme => {
    //   $vuetify.theme.themes[theme].primary = theme.themes[theme].primary
    //   $vuetify.theme.themes[theme].secondary = theme.themes[theme].secondary
    //   $vuetify.theme.themes[theme].accent = theme.themes[theme].accent
    //   $vuetify.theme.themes[theme].error = theme.themes[theme].error
    //   $vuetify.theme.themes[theme].info = theme.themes[theme].info
    //   $vuetify.theme.themes[theme].success = theme.themes[theme].success
    //   $vuetify.theme.themes[theme].warning = theme.themes[theme].warning
    // })
  }
}
